import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, CssBaseline, Divider, Stack, Toolbar } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isExpired } from 'react-jwt'
import { logOut } from '../../features/auth/authSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'
import MenuList from './MenuList'
import ProfileInfo from './ProfileInfo'

const drawerWidth = 240

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: '18px',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}))

const AuthNav = () => {
	const { user, googleToken } = useSelector((state) => state.auth)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const theme = useTheme()
	const [open, setOpen] = useState(false)

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (googleToken) {
			const isMyTokenExpired = isExpired(googleToken)

			if (isMyTokenExpired) {
				dispatch(logOut())
				navigate('/login')
				toast.warning('Your session has expired, login again')
			}
		}
	}, [navigate, dispatch, googleToken])

	return (
		<Box sx={{ flexGrow: 1 }}>
			<CssBaseline />
			<AppBar
				position='fixed'
				open={open}
				sx={{ color: '#0075b4' }}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'>
					<Toolbar>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							onClick={handleDrawerOpen}
							edge='start'
							sx={{
								marginRight: 5,
								...(open && { display: 'none' }),
							}}>
							<MenuIcon />
						</IconButton>
						<Logo fontSize='1.5rem' />
					</Toolbar>
					<Box>
						<ProfileInfo user={user} />
					</Box>
				</Stack>
			</AppBar>
			<Drawer
				variant='permanent'
				open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon
								color='#fff'
								fontSize='large'
							/>
						) : (
							<ChevronLeftIcon
								color='#fff'
								fontSize='large'
							/>
						)}
					</IconButton>
				</DrawerHeader>

				<MenuList />
			</Drawer>
		</Box>
	)
}

export default AuthNav
