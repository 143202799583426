import React from 'react'
import './flow-badge.css'

const FlowBadge = ({ title }) => {
	return (
		<div className='flow-badge-container'>
			<h4>{title}</h4>
		</div>
	)
}

export default FlowBadge
