import Box from '@mui/material/Box'
import '../styles/spinner.css'

const Spinner = () => {
	return (
		<Box className='spinnerContainer'>
			<Box className='spinner' />
		</Box>
	)
}

export default Spinner
